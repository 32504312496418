import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionFormComponent } from './accordion-form.component';
import { AccordionFormItem } from './directives/accordion-form-item.directive';
import { AccordionFormContent } from './directives/accordion-form-content.directive';
import { AccordionFormTitle } from './directives/accordion-form-title.directive';
import { AccordionFormHeader } from './directives/accordion-form-header.directive';

@NgModule({
  declarations: [
    AccordionFormComponent,
    AccordionFormItem,
    AccordionFormContent,
    AccordionFormTitle,
    AccordionFormHeader,
  ],
  imports: [CommonModule],
  exports: [
    AccordionFormComponent,
    AccordionFormItem,
    AccordionFormContent,
    AccordionFormTitle,
    AccordionFormHeader
  ]
})
export class AccordionFormModule {}
