import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  QueryList,
  OnInit,
  AfterViewInit,
  AfterContentInit
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AccordionFormItem } from './directives/accordion-form-item.directive';

@Component({
  selector: 'accordion-form',
  templateUrl: './accordion-form.component.html',
  styleUrls: ['./accordion-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [
    trigger('contentExpansion', [
      state(
        'expanded',
        style({
          height: '*',
          opacity: 1,
          visibility: 'visible',
        })
      ),
      state(
        'collapsed',
        style({
          height: '0px',
          opacity: 0,
          visibility: 'hidden',
          transform: 'scaleY(0)',
        })
      ),
      transition(
        'expanded <=> collapsed',
        animate('200ms cubic-bezier(.37,1.04,.68,.98)')
      ),
    ]),
  ],
})
export class AccordionFormComponent implements OnInit, AfterViewInit, AfterContentInit {
  expanded = new Set<number>();
  /**
   * Decides if the single item will be open at once or not.
   * In collapsing mode, toggling one would collapse others
   */
  @Input() simple: boolean = false;
  @Input() collapsing: boolean = true;
  @Input() label: string = '';
  @Input() accordionClass: string = '';
  @Input() form: FormGroup;

  @ContentChildren(AccordionFormItem) items: QueryList<AccordionFormItem>;

  public auxItems;

  get completedControls() {
    let completeds = 0;
    for (const control in this.form.controls) {
      if (this.form.controls[control].valid) {
        completeds += 1;
      }
    }
    return completeds;
  }

  get totalControls() {
    let total = 0;
    for (const control in this.form.controls) {
      total += 1;
    }
    return total;
  }

  constructor() {}

  ngAfterContentInit(): void {
    this.items.forEach((item, index) => {
      if (item.actived) this.expanded.add(index);
    })
    
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.items.changes.subscribe((c) => {
      c.toArray().forEach((item, index) => {
        if (item.actived) this.expanded.add(index);
      });
    });
  }

  /**
   * Make the toggle function available to be called from
   * outside.
   * @param index - index of the accordion item
   */
  getToggleState = (index: number) => {
    return this.toggleState.bind(this, index);
  };

  toggleState = (index: number) => {
    if (this.expanded.has(index)) {
      this.expanded.delete(index);
    } else {
      if (this.collapsing) {
        this.expanded.clear();
      }
      this.expanded.add(index);
    }
  };
}
