import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  public loadNuibizScript(renderer: Renderer2): void {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = env.NIUBIZ_API;
    renderer.appendChild(this.document.body, script);
  }

}
