import {
  transition,
  trigger,
  query,
  style,
  animate,
  group,
} from '@angular/animations';
export const slideInAnimation = trigger('routeAnimations', [
  transition('* => *', [
    query(':leave', style({ opacity: 0, display: 'none' }), {
      optional: true,
    }),
    group([
      query(
        ':enter',
        [
          style({ opacity: 0, display: 'block' }),
          animate('1s ease', style({ opacity: 1 })),
        ],
        { optional: true }
      )
    ]),
  ]),
]);
