import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input() color: string = '';
  // Colors: https://getbootstrap.com/docs/4.4/components/spinners/#colors
  @Input() small: boolean = false;


  constructor() { }

  ngOnInit(): void {
  }

}
