<div class="side-nav-broker" [class.actived]="isActived" #sideNavBroker>
  <div class="side-nav-broker__header">
    <div class="side-nav-broker__header__top">
      <img
        class="side-nav-broker__logo"
        alt="Logo iglu"
        src="/assets/img/logo-iglu-light-blue-vertical.png"
      />
      <div class="side-nav-broker__header__close" (click)="hidingNavigation()"></div>
    </div>
    <div *ngIf="user" class="side-nav-broker__header__username bold">
      ¡Hola {{user?.username}}!
    </div>
  </div>

  <div class="side-nav-broker__body">
    <a class="side-nav-broker__link" (click)="goToQuote()" [ngClass]="{'selected': selectQuoteBroker}">
      <img
        class="side-nav-broker__link__img"
        src="/assets/img/icon-quote-now.png"
        alt="Cotiza ahora"
      />
      <span class="side-nav-broker__link__text">Cotiza ahora</span>
    </a>
    <a class="side-nav-broker__link" (click)="goToQuoteHistoryBroker()" [ngClass]="{'selected': selectQuoteHistoryBroker}">
      <img
        class="side-nav-broker__link__img"
        src="/assets/img/icon-history.png"
        alt="Cotizaciones anteriores"
      />
      <div class="d-flex flex-column align-items-start">
        <span class="side-nav-broker__link__text">Cotizaciones anteriores</span>
      </div>
    </a>
    <a class="side-nav-broker__link disabled">
      <img
        class="side-nav-broker__link__img"
        src="/assets/img/icon-sales.png"
        alt="Ventas"
      />
      <div class="d-flex flex-column align-items-start">
        <span class="side-nav-broker__link__label">¡PRONTO!</span>
        <span class="side-nav-broker__link__text">Ventas</span>
      </div>
    </a>
    <a class="side-nav-broker__link disabled">
      <img
        class="side-nav-broker__link__img"
        src="/assets/img/icon-endosos.png"
        alt="Cotizaciones anteriores"
      />
      <div class="d-flex flex-column align-items-start">
        <span class="side-nav-broker__link__label">¡PRONTO!</span>
        <span class="side-nav-broker__link__text">Endosos</span>
      </div>
    </a>
    <a class="side-nav-broker__link disabled">
      <img
        class="side-nav-broker__link__img"
        src="/assets/img/icon-renovations.png"
        alt="Cotizaciones anteriores"
      />
      <div class="d-flex flex-column align-items-start">
        <span class="side-nav-broker__link__label">¡PRONTO!</span>
        <span class="side-nav-broker__link__text">Renovaciones</span>
      </div>
    </a>
    <a class="side-nav-broker__link disabled">
      <img
        class="side-nav-broker__link__img"
        src="/assets/img/icon-claims.png"
        alt="Cotizaciones anteriores"
      />
      <div class="d-flex flex-column align-items-start">
        <span class="side-nav-broker__link__label">¡PRONTO!</span>
        <span class="side-nav-broker__link__text">Siniestros</span>
      </div>
    </a>
    <a class="side-nav-broker__link disabled">
      <img
        class="side-nav-broker__link__img"
        src="/assets/img/icon-need-help.png"
        alt="Cotizaciones anteriores"
      />
      <div class="d-flex flex-column align-items-start">
        <span class="side-nav-broker__link__label">¡PRONTO!</span>
        <span class="side-nav-broker__link__text">¿Necesitas ayuda?</span>
      </div>
    </a>
  </div>

  <div class="side-nav-broker__footer">
    <button class="side-nav-broker__link" (click)="closeSection()">
      <img
        class="side-nav-broker__link__img"
        src="/assets/img/icon-logout.png"
        alt=""
      />
      <span class="side-nav-broker__link__text bold">Cerrar sesión</span>
    </button>
  </div>
</div>

<popup
class="popup-logout"
[(show)]="popupSettings.show"
[isCustom]="true"
[options]="popupSettings.options"
>      
  <div class="col">
    <div class="row align-items-center d-flex flex-sm-row justify-content-center">
      <img
        class="popup-logout__img"
        src="/assets/img/icon-logout-light-blue.png"
        alt=""
      />
    </div>
    <div class="row align-items-center d-flex flex-sm-row justify-content-center">
      <p class="popup-logout__title bold"><strong>¿Estás seguro que deseas cerrar sesión?</strong></p>
    </div>
    <div customFooter class="row align-items-center d-flex flex-sm-row justify-content-center">
      <app-button
        text="Volver"
        type="secondary"
        color="secondary"
        class="mr-1 mr-md-3"
        buttonClass="popup__btn"
        (click)="cancel()"
      >
      </app-button>
      <app-button
        type="primary"
        color="primary"
        buttonClass="popup__btn"
        text="Cerrar sesión"
        (click)="logout()"
      >
      </app-button>
    </div>
  </div>
  
</popup>
