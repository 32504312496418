<a
  class="dropdown-btn medium color-black-1"
  [ngClass]="{
    blue: blue,
    light: light
  }"
  *ngIf="split"
  (click)="isTitleLinkable ? goRoute(titleRoute) : false"
  >{{ title }}</a
>
<div
  ngbDropdown
  class="d-inline-block"
  [ngClass]="customClass"
  [class.drowpdown-group]="split"
>
  <a
    class="dropdown-btn medium color-black-1"
    [id]="'dropdown-' + id"
    ngbDropdownToggle
    [class.dropdown-toggle-split]="split"
    [ngClass]="{
      blue: blue,
      light: light
    }"
  >
    <ng-template [ngIf]="!split">
      {{ title }}
    </ng-template>
    <i *ngIf="urlImg !== ''"><img class="mr-1" [src]="urlImg" alt="" /></i>
  </a>
  <div ngbDropdownMenu [attr.aria-labelledby]="'dropdown-' + id">
    <ng-content #dropdownLinks></ng-content>
  </div>
</div>
