import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { PunkuRoutes } from 'src/app/core/utils/punkuRoutes';
import { FormStorageManagerService } from 'src/app/core/services/form-storage-manager.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  private routes = PunkuRoutes.ROUTES.Pages;
  public showFooter = true;
  public tokenBroker = null;
  
  constructor(private router: Router,
    private formStorageManagerService: FormStorageManagerService
    ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.showFooter =
          event.url.substring(1) !== this.routes.Continue.fullPath;
      }
    });
  }

  ngOnInit(): void {}

  public callMeBack() {
    this.router.navigate([this.routes.ContactSolicitude.fullPath]);
  }

  public lostPolicy() {
    this.router.navigate([this.routes.PolicySearch.fullPath]);
  }

  public registerSinister() {
    this.router.navigate([this.routes.ClaimRegister.fullPath]);
  }

  public cancelPolicy() {
    this.router.navigate([this.routes.PolicyCancellation.fullPath]);
  }

  public claims() {
    this.router.navigate([this.routes.Claims.fullPath]);
  }
  public goAboutUs() {
    this.router.navigate([this.routes.AboutUs.fullPath]);
  }
  public goPropertyInsurance() {
    this.router.navigate([this.routes.Insurances.Property.fullPath]);
  }
  public goToRC() {
    this.router.navigate([this.routes.Insurances.CivilLiability.fullPath]);
  }
  public goToSendDocuments() {
    this.router.navigate([this.routes.SendDocuments.fullPath]);
  }
  public goFAQ() {
    this.router.navigate([this.routes.Faq.fullPath]);
  }
  public goQuote(): void {
    this.router.navigate([this.routes.SelectFlow.fullPath]);
  }
  public goQuoteBroker() {
    this.tokenBroker = this.formStorageManagerService.getBrokerToken();
    if (this.tokenBroker) {
      this.router.navigate([this.routes.HomeBroker.fullPath]);
    } else {
      this.router.navigate([this.routes.LoginBroker.fullPath]);
    }
  }
  public faqInsuranges() {
    this.router.navigate([PunkuRoutes.ROUTES.Pages.Faq.path], {
      queryParams: { type: 'IN' },
    });
  }
  public faqPrices() {
    this.router.navigate([PunkuRoutes.ROUTES.Pages.Faq.path], {
      queryParams: { type: 'PR' },
    });
  }
  public faqOthers() {
    this.router.navigate([PunkuRoutes.ROUTES.Pages.Faq.path], {
      queryParams: { type: 'OT' },
    });
  }
}
