import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownComponent implements OnInit {
  @Input() title: string;
  @Input() titleRoute;
  @Input() isTitleLinkable: boolean = true;
  @Input() urlImg: string;
  @Input() customClass: string;
  @Input() blue: boolean = false;
  @Input() light: boolean = false;
  @Input() split: boolean = false;

  public id: number;

  @Output() onClickTitle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private router: Router) {
    this.id = Math.floor(100000 + Math.random() * 900000);
  }

  ngOnInit(): void {}

  goRoute(): void {
    this.onClickTitle.emit(true);
    if (!this.titleRoute) return;
    this.router.navigate([this.titleRoute]);
  }
}
