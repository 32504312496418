import {
  Component,
  OnInit,
  ViewEncapsulation,
  HostListener,
  Input,
} from '@angular/core';

@Component({
  selector: 'whatsapp-btn',
  templateUrl: './whatsapp-btn.component.html',
  styleUrls: ['./whatsapp-btn.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WhatsappBtnComponent implements OnInit {
  public tooltip = {
    placement: 'left',
    message: `<p class="whatsapp-btn-tooltip__title color-black-6 extra-bold">
                ¡Hola! Somos <span class="color-light-blue-1 extra-bold">IGLU</span>
                <img class="whatsapp-btn-tooltip__logo" src="/assets/img/svg/avla-isotipo.svg"/>
              </p>
              <p class="whatsapp-btn-tooltip__description color-black-6 medium">
                Habla con nosotros al instante y aclara tus dudas aquí.
              </p>`,
    trigger: 'focus',
    close: true,
  };

  private isDesktop: boolean = false;

  public initOpen: boolean = true;
  public hasCloseManual: boolean = true;

  public showTooltip: boolean = true;

  @Input() isHomePage: boolean = false;

  constructor() {
    this.isDesktop = window.matchMedia('(min-width: 960px)').matches;
    this.tooltip.trigger = this.isDesktop ? 'hover' : 'focus';
  }

  // Usado solo para pruebas de desktop <=> mobile (Simulado)
  @HostListener('window:resize')
  onResize() {
    this.isDesktop = window.matchMedia('(min-width: 960px)').matches;
    this.tooltip.trigger = this.isDesktop ? 'hover' : 'focus';
  }

  ngOnInit(): void {
    if (!this.isHomePage) {
      this.closeTooltip(true);
    }
  }

  closeTooltip($e) {
    if ($e) {
      this.showTooltip = false;
      this.initOpen = false;
      this.hasCloseManual = false;
      this.tooltip.close = false;

      setTimeout(() => {
        this.showTooltip = true;
      }, 200);
    }
  }
}
