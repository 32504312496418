<div class="whatsapp-btn-container">
  <a
    class="whatsapp-btn"
    href="https://wa.me/send?phone=51922470491&text=Hola%20IGLU!%20Tengo%20algunas%20dudas"
    target="_blank"
  >
    <img
      class="whatsapp-btn__icon"
      src="/assets/img/svg/whatsapp-icon-white.svg"
    />
    <ng-template [ngIf]="showTooltip">
      <app-tooltip
        class="whatsapp-btn-tooltip"
        [tooltipClass]="'whatsapp-btn-tooltip__bubble'"
        [tooltipData]="tooltip"
        [withIcon]="false"
        [initOpen]="initOpen"
        [hasCloseManual]="hasCloseManual"
        [customIconClose]="true"
        (onCloseManual)="closeTooltip($event)"
      ></app-tooltip>
    </ng-template>
  </a>
</div>
