import { OnDestroy } from '@angular/core';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopupComponent implements OnInit, OnDestroy {
  @Input() isCustom: boolean = false;
  @Input() show: boolean = false;
  @Input() options: any = {
    acceptButton: 'Acepto',
    closeButton: true,
    typePopup: 'accept',
  };
  @Input() data: any = {};
  @Output() showChange = new EventEmitter<boolean>();
  @Output() replyEvent = new EventEmitter<Object>();
  @Input() popupClass: string;
  @Input() overlayYellow: boolean = false;
  @Input() overlayRed: boolean = false;
  @Input() titleSmall: boolean = false;

  public typesPopup = ['accept', 'confirm', 'form', 'large'];

  constructor() {}

  ngOnDestroy(): void {
    document.getElementsByTagName('body')[0].style.overflowY = 'auto'
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.show?.currentValue) {
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
    } else {
      document.getElementsByTagName('body')[0].style.overflowY = 'auto'
    }

    if (!!changes.options?.currentValue) {
      this.checkTypePopup();
    }
  }

  private checkTypePopup() {
    this.options.typePopup =
      this.options?.typePopup &&
      this.typesPopup.includes(this.options?.typePopup)
        ? this.options?.typePopup
        : 'accept';
  }

  public onAccept() {
    this.replyEvent.emit({ value: true, trigger: this.options?.trigger });
    if (this.options?.typePopup !== 'form') this.onClose();
  }

  public onCancel() {
    this.replyEvent.emit({ value: false, trigger: this.options?.trigger });
    this.onClose();
  }

  public onClose() {
    this.replyEvent.emit({ value: false, trigger: 'close' });
    this.showChange.emit(false);
  }
}
