<app-header (activedSidenav)="onActivedSidenav($event)"></app-header>
<ng-template
  [ngIf]="isPageWLoader && !(isStepThree || isStepFour)"
>
  <loader [show]="showLoader"></loader>
</ng-template>
<ng-template [ngIf]="isStepThree || isStepFour">
  <loader [invisible]="true" [show]="showLoader"></loader>
</ng-template>

<toast
  [duration]="toast?.duration"
  [type]="toast?.type"
  [body]="toast?.body"
  [(show)]="toast.show"
></toast>

<main class="container-router-oulet" [@routeAnimations]="prepareRoute(outlet)">
  <app-side-nav
    *ngIf="!isBrokerUser"
    [(isActived)]="showSidenav"
  ></app-side-nav>
  <app-side-nav-broker
    *ngIf="isBrokerUser"
    [(isActived)]="showSidenavBroker"
  ></app-side-nav-broker>
  <router-outlet
    (activate)="onActivate($event)"
    #outlet="outlet"
  ></router-outlet>
</main>

<ng-template [ngIf]="showWspBtn">
  <whatsapp-btn [isHomePage]="!isPageWLoader"></whatsapp-btn>
</ng-template>

<app-footer></app-footer>
