<div 
  class="toast-container" 
  [ngClass]="show ? 'show' : ''"
>
  <div
    class="toast"
    [ngClass]="type ? 'toast--' + type : 'toast--error'"
  >
    <div class="toast-body">
      <p [innerHtml]="body"></p>
    </div>
  </div>
</div>
