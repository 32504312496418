import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import localES from '@angular/common/locales/es-PE';
import { registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { SideNavModule } from './shared/layout/side-nav/side-nav.module';
import { LoaderModule } from './shared/loader/loader.module';
import { ToastModule } from './shared/toast/toast.module';
import { DropdownModule } from './shared/dropdown/dropdown.module';
import { WhatsappBtnModule } from './shared/whatsapp-btn/whatsapp-btn.module';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileSaverModule } from 'ngx-filesaver';

import { CommonDataService } from 'src/app/core/services/common-data.service';
import { SideNavBrokerModule } from './shared/layout/side-nav-broker/side-nav-broker.module';

registerLocaleData(localES, 'es');

@NgModule({
  declarations: [AppComponent, FooterComponent, HeaderComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    LoaderModule,
    HttpClientModule,
    ToastModule,
    NgbModule,
    DropdownModule,
    SideNavModule,
    RecaptchaV3Module,
    FileSaverModule,
    SideNavBrokerModule,
    WhatsappBtnModule,
  ],
  providers: [
    CommonDataService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.CAPTCHA_SITE_KEY },
    {
      provide: 'googleTagManagerId',
      useValue: environment.TAG_MANAGER_CONTAINER_ID,
    },
    { provide: LOCALE_ID, useValue: 'es' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
