<section class="accordion {{accordionClass}}" [class.simple]="simple">
  <div
    *ngFor="let item of items; index as i"
    class="accordion__item"
    [class.disabled]="item.disabled"
    [class.active]="expanded.has(i)"
    [class.shadow-item]="item.shadow"
    [class.hidden]="!item.hidden"
  >
    <ng-container
      [ngTemplateOutlet]="item?.customHeader?.templateRef || defaultHeader"
      [ngTemplateOutletContext]="{
        $implicit: item,
        index: i,
        toggle: getToggleState(i),
        activated: expanded.has(i)
      }"
    ></ng-container>
    <div
      class="accordion__content"
      [class.expanded]="expanded.has(i)"
      [@contentExpansion]="expanded.has(i) ? 'expanded' : 'collapsed'"
    >
      <ng-container
        *ngTemplateOutlet="item?.content?.templateRef"
      ></ng-container>
    </div>
  </div>
</section>

<ng-template #defaultHeader let-item let-index="index">
  <header
    class="accordion__header"
    [class.accordion__header--simple]="simple"
    (click)="item.disabled ? {} : toggleState(index)"
  >
    <ng-container
      *ngTemplateOutlet="item?.customTitle?.templateRef || defaultTitle"
    ></ng-container>

    <div class="d-flex align-items-center">
      <ng-template [ngIf]="label != ''">
        <span
          class="mr-3 accordion__title bold"
          >{{ label }}</span
        >
      </ng-template>

      <ng-template [ngIf]="form">
        <span
          class="mr-3 accordion__completed color-light-blue-1"
          >{{ completedControls }} de {{ totalControls }}</span
        >
      </ng-template>
      <button class="accordion__toggle-btn" [disabled]="item.disabled">
        <img src="/assets/img/arrow_bottom_icon.png" alt="">
      </button>
    </div>
  </header>
  <ng-template #defaultTitle>
    <p
      class="accordion__title regular color-black-2"
      [class.accordion__title--simple]="simple"
    >
      {{ item?.title }}
    </p>
  </ng-template>
</ng-template>
