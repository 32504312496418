<div class="popup-container" [class.hide]="!show">
  <div class="overlay" [ngClass]="{'red': overlayRed, 'yellow': overlayYellow}"></div>
  <div
    class="popup"
    [ngClass]="'popup--' + options?.typePopup"
    [class.popup--img]="!!data?.imgHeader"
    *ngIf="!isCustom"
  >
    <div class="popup__close" *ngIf="options?.closeButton" (click)="onClose()">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.6222 6.89429L14.8492 11.6673L10.0763 6.89429C9.49059 6.30861 8.54063 6.30861 7.95495 6.89429L6.89429 7.95495C6.30861 8.54064 6.30861 9.49059 6.89429 10.0763L11.6673 14.8492L6.89429 19.6222C6.30861 20.2079 6.30861 21.1579 6.89429 21.7435L7.95495 22.8042C8.54063 23.3899 9.49059 23.3899 10.0763 22.8042L14.8492 18.0312L19.6222 22.8042C20.2079 23.3899 21.1578 23.3899 21.7435 22.8042L22.8042 21.7435C23.3899 21.1579 23.3899 20.2079 22.8042 19.6222L18.0312 14.8492L22.8042 10.0763C23.3899 9.49059 23.3899 8.54064 22.8042 7.95495L21.7435 6.89429C21.1578 6.30861 20.2079 6.30861 19.6222 6.89429Z"
          fill="#5B5959"
        />
      </svg>
    </div>
    <div *ngIf="!!data?.imgHeader" class="popup__img">
      <img [src]="data?.imgHeader" alt="Imagen" />
    </div>
    <div class="popup__header">
      <h2
        class="popup__title bold"
        [ngClass]="{ small : titleSmall}"
        [innerHtml]="data?.title"
      ></h2>
    </div>
    <div class="popup__content">
      <div
        class="popup__body"
        *ngIf="options?.typePopup === 'accept'"
        [innerHtml]="data?.content"
      ></div>
      <div
        class="popup__body"
        *ngIf="
          options?.typePopup === 'confirm' || options?.typePopup === 'form'
        "
      >
        <ng-content #customPopup></ng-content>
      </div>
      <div
        class="popup__actions text-center"
        *ngIf="!!options?.acceptButton || !!options?.cancelButton"
      >
        <app-button
          *ngIf="!!options?.cancelButton"
          [text]="options?.cancelButton"
          type="secondary"
          (click)="onCancel()"
          buttonClass="popup__btn"
          class="mr-3"
          >
        </app-button>
        <app-button
        *ngIf="!!options?.acceptButton"
          [text]="options?.acceptButton"
          type="primary"
          (click)="onAccept()"
          buttonClass="popup__btn"
        >
        </app-button>
      </div>
    </div>
  </div>
  <div
    class="popup popup--custom {{ popupClass }}"
    [ngClass]="'popup--' + options?.typePopup"
    *ngIf="isCustom"
  >
    <div class="popup__close" *ngIf="options?.closeButton" (click)="onClose()">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.6222 6.89429L14.8492 11.6673L10.0763 6.89429C9.49059 6.30861 8.54063 6.30861 7.95495 6.89429L6.89429 7.95495C6.30861 8.54064 6.30861 9.49059 6.89429 10.0763L11.6673 14.8492L6.89429 19.6222C6.30861 20.2079 6.30861 21.1579 6.89429 21.7435L7.95495 22.8042C8.54063 23.3899 9.49059 23.3899 10.0763 22.8042L14.8492 18.0312L19.6222 22.8042C20.2079 23.3899 21.1578 23.3899 21.7435 22.8042L22.8042 21.7435C23.3899 21.1579 23.3899 20.2079 22.8042 19.6222L18.0312 14.8492L22.8042 10.0763C23.3899 9.49059 23.3899 8.54064 22.8042 7.95495L21.7435 6.89429C21.1578 6.30861 20.2079 6.30861 19.6222 6.89429Z"
          fill="#5B5959"
        />
      </svg>
    </div>
    <div class="h-100 d-flex flex-column">
      <ng-content #customBody></ng-content>
      <ng-content #customFooter></ng-content>
    </div>
  </div>
</div>
