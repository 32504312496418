import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() show: boolean = false;
  @Input() invisible: boolean = false;
  @Input() phraseDuration: number = 4000;
  @Input() phrasesLoader: Array<any> = [
    {
      phrase:
        '<p>Tu seguro no debes pensarlo, debes tomarlo porque los siniestros no se planean, <strong>llegan</strong></p>',
    },
    {
      phrase:
        '<p>Sentirse seguro es saber <strong>que cuentas con AVLA</strong></p>',
    },
    {
      phrase:
        '<p>¿Cuál es el costo de un seguro?, probablemente más barato que el costo de <strong>no tenerlo</strong></p>',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
