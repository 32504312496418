<footer class="footer display-desktop" *ngIf="showFooter">
  <div class="footer__container">
    <div class="row flex-nowrap --lg-gap">
      <div class="col col-xl-4 d-flex order-1">
        <article class="footer__item footer__item--line">
          <a class="nav__logo" [routerLink]="routes.Home.fullPath">
            <img
              class="logo__iglu"
              alt="Logo iglu"
              src="assets/img/svg/logo-horizontal-white.svg"
            />
          </a>
          <div class="d-flex m-10">
            <a href="https://www.facebook.com/igluseguros" target="_blank">
              <img src="assets/img/fb-white-icon.png" alt="facebook iglu" />
            </a>
            <a href="https://www.instagram.com/igluseguros/" target="_blank">
              <img src="assets/img/ins-white-icon.png" alt="instagram iglu" />
            </a>
            <a
              href="https://www.linkedin.com/company/igluseguros/"
              target="_blank"
            >
              <img src="assets/img/lid-white-icon.png" alt="linkedin iglu" />
            </a>
          </div>
          <p class="mb-0">
            <a
              href="mailto:lucas@igluseguros.com"
              class="footer__item__text regular text-white-1"
              >lucas@igluseguros.com</a
            >
            <span class="footer__item__text regular text-white-1">
              | Tel. +51 1 715-4400</span
            >
          </p>
          <address class="footer__item__text regular">
            <a
              href="https://goo.gl/maps/vmgqB9vDBYNE7JfW8"
              target="_blank"
              class="text-white-1"
            >
              Calle Begonias 415, 3er Piso, San Isidro – Lima, Perú
            </a>
          </address>
        </article>
      </div>
      <div class="col col-xl-1 d-flex order-2">
        <article class="footer__item">
          <h1 class="footer__item__title">
            <a class="footer__item__title__link bold" (click)="goAboutUs()"
              >Nosotros</a
            >
          </h1>
        </article>
      </div>
      <div class="col col-lg-2 col-xl-2 d-flex order-2">
        <article class="footer__item">
          <h1 class="footer__item__title bold">Nuestros seguros</h1>
          <ul class="footer__item__list">
            <li class="footer__item__text regular">
              <a (click)="goPropertyInsurance()" class="text-white-1">
                Seguro de propiedad
              </a>
            </li>
            <li class="footer__item__text regular">
              <a (click)="goToRC()" class="text-white-1">
                Responsabilidad Civil
              </a>
            </li>
          </ul>
        </article>
      </div>
      <div class="col col-lg-3 col-xl-3 d-flex order-2">
        <article class="footer__item">
          <h1 class="footer__item__title bold">¿Necesitas ayuda?</h1>
          <ul class="footer__item__list">
            <li class="footer__item__text regular">
              <a (click)="callMeBack()" class="text-white-1">
                Quiero que me llamen
              </a>
            </li>
            <li class="footer__item__text regular">
              <a (click)="lostPolicy()" class="text-white-1">
                Perdí mi póliza
              </a>
            </li>
            <li class="footer__item__text regular">
              <a (click)="registerSinister()" class="text-white-1">
                Quiero registrar un Siniestro
              </a>
            </li>
            <li class="footer__item__text regular">
              <a (click)="cancelPolicy()" class="text-white-1">
                Cancelar mi póliza
              </a>
            </li>
            <li class="footer__item__text regular">
              <a (click)="goFAQ()" class="text-white-1">
                Preguntas frecuentes
              </a>
            </li>
            <li class="footer__item__text regular">
              <a (click)="claims()" class="text-white-1">
                Atención de reclamos
              </a>
            </li>
            <li class="footer__item__text regular">
              <a (click)="goToSendDocuments()" class="text-white-1">
                Regulariza tus documentos
              </a>
            </li>
          </ul>
        </article>
      </div>
    </div>
  </div>
</footer>
