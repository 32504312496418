import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PunkuRoutes } from './core/utils/punkuRoutes';
import { sectionsMetadata } from './core/utils/sectionsMetadata';

const routes: Routes = [
  {
    path: PunkuRoutes.ROUTES.Clean,
    data: sectionsMetadata.homePage,
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  { path: '**', redirectTo: PunkuRoutes.ROUTES.Clean },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 35]
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
