<header>
  <!-- NAV -->
  <nav
    *ngIf="!isBrokerUser"
    class="header nav d-flex flex-column text-white fixed-top"
    [class.nav--scrolled]="scrolled"
    [ngClass]="headerClass"
  >
    <div class="display-mobile">
      <div class="container d-flex justify-content-center align-items-center">
        <button (click)="toggleSidenav()" class="nav__btn-menu mr-3">
          <img [src]="getBurger()" alt="burger icon" />
        </button>
        <a (click)="goHome()" class="nav__logo">
          <img
            class="logo__iglu logo__iglu--mobile"
            [src]="getLogo()"
            alt="logo iglu"
          />
        </a>
      </div>
    </div>

    <div class="display-desktop">
      <div class="nav__container section-home">
        <a class="nav__logo" (click)="goHome()">
          <img class="logo__iglu" alt="logo iglu" [src]="getLogo()" />
        </a>

        <div
          class="nav__internal-links d-flex justify-content-end align-items-center"
          *ngIf="!onlyLogo"
        >
          <a (click)="goAboutUs()" class="medium">Nosotros</a>

          <app-dropdown
            [split]="false"
            [light]="colorLogo === 'white' && !scrolled"
            customClass="dropdown--header"
            title="Nuestros seguros"
            (onClickTitle)="onClickMenuInsurances($event)"
          >
            <div dropdownLinks>
              <button ngbDropdownItem (click)="goPropertyInsurance()">
                Seguro de propiedad
              </button>
              <button ngbDropdownItem (click)="goRC()">
                Responsabilidad Civil
              </button>
              <button ngbDropdownItem (click)="goPet()">
                Mascotas
              </button>
            </div>
          </app-dropdown>

          <app-dropdown
            [split]="false"
            [isTitleLinkable]="false"
            [light]="colorLogo === 'white' && !scrolled"
            customClass="dropdown--header"
            title="Centro de ayuda"
          >
            <div dropdownLinks>
              <button ngbDropdownItem (click)="callMeBack()">
                ¿Quieres que te llamemos?
              </button>
              <button ngbDropdownItem (click)="lostPolicy()">
                Perdí mi póliza
              </button>
              <button ngbDropdownItem (click)="registerSinister()">
                Quiero registrar un siniestro
              </button>
              <button ngbDropdownItem (click)="cancelPolicy()">
                Cancelar mi póliza
              </button>
              <button ngbDropdownItem (click)="goFAQ()">
                Preguntas frecuentes
              </button>
            </div>
          </app-dropdown>
          <!-- <ng-template [ngIf]="!initBroker">
            <a (click)="goFAQ()" class="medium">Preguntas Frecuentes</a>
          </ng-template> -->
          <a
            *ngIf="visibleBtnQuoteBroker"
            (click)="goQuoteBroker()"
            class="btn btn-light color-black-2 broker"
            id="btn-quote-header"
          >
            Soy Corredor
          </a>
        </div>

        <ng-template [ngIf]="showBtnQuote()">
          <a
            (click)="goQuote()"
            class="btn btn-primary nav__btn-quote"
            id="btn-quote-header"
          >
            Compra ahora
          </a>
        </ng-template>
      </div>
    </div>
  </nav>

  <!-- BROKER NAV -->
  <nav
    *ngIf="isBrokerUser"
    class="fixed-top nav-broker"
    [class.nav--scrolled]="scrolled"
    [ngClass]="headerClass"
  >
    <button
      (click)="toggleSidenav(); $event.stopPropagation()"
      class="btn nav-broker__btn"
    >
      <img [src]="getIconBurgerBroker()" alt="" />
    </button>
    <img class="logo__iglu" alt="Logo iglu" [src]="getLogo()" />
  </nav>
</header>
