import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from './../../button/button.module';
import { SideNavComponent } from './side-nav.component';
import { AccordionFormModule } from './../../accordion-form/accordion-form.module';
import { DropdownModule } from "./../../dropdown/dropdown.module";

@NgModule({
  declarations: [SideNavComponent],
  imports: [CommonModule, ButtonModule, AccordionFormModule, DropdownModule],
  exports: [SideNavComponent],
})
export class SideNavModule {}
