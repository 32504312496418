import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  SimpleChanges,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { LoginService } from '../../../features/broker/services/login.service';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { FormStorageManagerService } from 'src/app/core/services/form-storage-manager.service';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import { PunkuRoutes } from 'src/app/core/utils/punkuRoutes';

@Component({
  selector: 'app-side-nav-broker',
  templateUrl: './side-nav-broker.component.html',
  styleUrls: ['./side-nav-broker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SideNavBrokerComponent implements OnInit {
  @Input() isActived: boolean = true;
  @Output() isActivedChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public routes = PunkuRoutes.ROUTES.Pages;

  public selectQuoteBroker: boolean = true;
  public selectQuoteHistoryBroker: boolean = false;

  public popupSettings = {
    options: { type: 'confirm' },
    data: {},
    show: false,
  };
  @ViewChild('sideNavBroker') sideNavBroker: ElementRef;

  public user = null;

  constructor(
    private commonDataService: CommonDataService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private LoginService: LoginService,
    private router: Router,
    private renderer: Renderer2,
    private formStorageManagerService: FormStorageManagerService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        // if (event.url) this.selectQuoteBroker = true;

        this.selectQuoteBroker =
          event.url.includes(this.routes.QuoteBroker.QuoteBrokerCompany.fullPath) ||
          event.url.includes(this.routes.QuoteBroker.QuoteBrokerPerson.fullPath) ||
          event.url.includes(this.routes.HomeBroker.fullPath) ||
          event.url.includes(this.routes.SelectFlowBroker.fullPath) ||
          event.url.includes(this.routes.Niubiz.PropertyBroker.fullPath);

        this.selectQuoteHistoryBroker = event.url.includes(
          this.routes.QuoteHistoryBroker.fullPath
        );
      }
    });
  }

  ngOnInit(): void {
    this.user = this.formStorageManagerService.getBrokerUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.sideNavBroker) {
        if (!this.sideNavBroker.nativeElement.contains(e.target)) {
          this.hidingNavigation();
        }
      }
    });
  }

  public hidingNavigation() {
    this.isActived = false;
    this.commonDataService.changeActiveSideNavBroker(this.isActived);
  }

  public closeSection() {
    this.popupSettings.show = true;
  }

  public async logout() {
    const igluToken = this.formStorageManagerService.getBrokerToken();
    this.commonDataService.changeLoader(true);

    try {
      let recaptchaToken = await this.recaptchaV3Service
        .execute('validateCreds')
        .toPromise();
      let response: any = await this.LoginService.logoutBroker(
        igluToken,
        recaptchaToken
      ).toPromise();

      if (response?.result?.success) {
        this.successfulLogout();
      }
    } catch (err) {
      this.commonDataService.changeLoader(false);
    }
  }

  private successfulLogout() {
    this.hidingNavigation();
    this.formStorageManagerService.removeBrokerUser();
    this.router.navigate([this.routes.Home.fullPath]);
  }

  public cancel() {
    this.popupSettings.show = false;
  }

  public goToQuote() {
    this.router.navigate([this.routes.HomeBroker.fullPath]);
  }

  public goToQuoteHistoryBroker() {
    this.router.navigate([this.routes.QuoteHistoryBroker.fullPath]);
  }
}
