<div class="sidenav" [class.actived]="isActived">
  <div class="sidenav__header">
    <a (click)="goHome()">
      <img
        class="sidenav__logo"
        src="/assets/img/logo-horizontal-light-blue.png"
        alt="Logo AVLA"
      />
    </a>
    <img
      (click)="hidingNavigation()"
      src="/assets/img/svg/icon-close.svg"
      alt=""
    />
  </div>
  <div class="sidenav__main">
    <div class="sidenav__item" (click)="goHome()">
      <div class="sidenav__item__img">
        <img src="/assets/img/svg/menu-home.svg" alt="Inicio" />
      </div>
      <label class="sidenav__item__text color-black-6 bold">Inicio</label>
    </div>

    <!--  -->

    <div class="sidenav__item" (click)="goAboutUs()">
      <div class="sidenav__item__img">
        <img src="/assets/img/svg/menu-about-us.svg" alt="Nosotros" />
      </div>
      <label class="sidenav__item__text color-black-6 bold">Nosotros</label>
    </div>

    <!--  -->

    <div class="sidenav__item" (click)="showInsurances = !showInsurances">
      <div class="d-flex align-items-center">
        <div class="sidenav__item__img">
          <img
            src="/assets/img/svg/menu-insurances.svg"
            alt="Nuestros seguros"
          />
        </div>
        <label class="sidenav__item__text color-black-6 bold"
          >Nuestros seguros</label
        >
      </div>
      <img
        class="sidenav__item__arrow"
        [class.sidenav__item__arrow--close]="showInsurances"
        src="/assets/img/svg/icon-arrow-primary.svg"
        alt="Nuestros seguros"
      />
    </div>
    <div class="sidenav__accordion" [class.show]="showInsurances">
      <div
        class="sidenav__item sidenav__item--child"
        (click)="goPropertyInsurance()"
      >
        <div class="sidenav__item__img">
          <img
            src="/assets/img/svg/icon-insurance-property.svg"
            alt="Seguro de propiedad"
          />
        </div>
        <label class="sidenav__item__text color-black-6 semi-bold"
          >Seguro de Propiedad</label
        >
      </div>
      <div class="sidenav__item sidenav__item--child" (click)="goRC()">
        <div class="sidenav__item__img">
          <img
            src="/assets/img/svg/icon-insurance-civil-liability.svg"
            alt="Responsabilidad civil"
          />
        </div>
        <label class="sidenav__item__text color-black-6 semi-bold"
          >Responsabilidad Civil</label
        >
      </div>
      <div
        class="sidenav__item sidenav__item--child"
        (click)="goPet()"
      >
        <div class="sidenav__item__img">
          <img src="/assets/img/svg/icon-insurance-pet.svg" alt="Mascota" />
        </div>
        <label class="sidenav__item__text color-black-6 semi-bold"
          >Mascotas</label
        >
      </div>
    </div>

    <!--  -->

    <div class="sidenav__item" (click)="showHelpCenter = !showHelpCenter">
      <div class="d-flex align-items-center">
        <div class="sidenav__item__img">
          <img src="/assets/img/svg/menu-help.svg" alt="Centro de Ayuda" />
        </div>
        <label class="sidenav__item__text color-black-6 bold"
          >Centro de Ayuda</label
        >
      </div>
      <img
        class="sidenav__item__arrow"
        [class.sidenav__item__arrow--close]="showHelpCenter"
        src="/assets/img/svg/icon-arrow-primary.svg"
        alt="Centro de Ayuda"
      />
    </div>
    <div class="sidenav__accordion" [class.show]="showHelpCenter">
      <div class="sidenav__item sidenav__item--child" (click)="callMeBack()">
        <div class="sidenav__item__img">
          <img
            src="/assets/img/svg/icon-call-me-back.svg"
            alt="¿Quieres que te llamemos?"
          />
        </div>
        <label class="sidenav__item__text color-black-6 semi-bold"
          >¿Quieres que te llamemos?</label
        >
      </div>
      <div class="sidenav__item sidenav__item--child" (click)="lostPolicy()">
        <div class="sidenav__item__img">
          <img
            src="/assets/img/svg/icon-lost-policy.svg"
            alt="Perdí mi póliza"
          />
        </div>
        <label class="sidenav__item__text color-black-6 semi-bold"
          >Perdí mi póliza</label
        >
      </div>
      <div
        class="sidenav__item sidenav__item--child"
        (click)="registerSinister()"
      >
        <div class="sidenav__item__img">
          <img
            src="/assets/img/svg/icon-claim.svg"
            alt="Quiero registrar un siniestro"
          />
        </div>
        <label class="sidenav__item__text color-black-6 semi-bold"
          >Quiero registrar un siniestro</label
        >
      </div>
      <div class="sidenav__item sidenav__item--child" (click)="cancelPolicy()">
        <div class="sidenav__item__img">
          <img
            src="/assets/img/svg/icon-cancel-policy.svg"
            alt="Cancelar mi póliza"
          />
        </div>
        <label class="sidenav__item__text color-black-6 semi-bold"
          >Cancelar mi póliza</label
        >
      </div>
      <div class="sidenav__item sidenav__item--child" (click)="goFaq()">
        <div class="sidenav__item__img">
          <img src="/assets/img/svg/icon-faq.svg" alt="Preguntas frecuentes" />
        </div>
        <label class="sidenav__item__text color-black-6 semi-bold"
          >Preguntas frecuentes</label
        >
      </div>
      <div class="sidenav__item sidenav__item--child" (click)="goClaims()">
        <div class="sidenav__item__img">
          <img
            src="/assets/img/icon-claims-sidenav.png"
            width="24px"
            height="24px"
            alt="Atención de Reclamos"
          />
        </div>
        <label class="sidenav__item__text color-black-6 semi-bold"
          >Atención de Reclamos</label
        >
      </div>
    </div>

    <!--  -->

    <div class="sidenav__item" (click)="goQuoteBroker()">
      <div class="sidenav__item__img">
        <img src="/assets/img/svg/menu-broker.svg" alt="Soy Corredor" />
      </div>
      <label class="sidenav__item__text color-black-6 bold">Soy Corredor</label>
    </div>
  </div>
  <div class="sidenav__footer">
    <img
      class="sidenav__logo-footer"
      src="/assets/img/svg/logo-horizontal-black.svg"
      alt="facebook iglu"
    />
    <div class="sidenav__social-network">
      <a target="_blank" href="https://www.facebook.com/igluseguros">
        <img src="/assets/img/fb-circle.png" alt="facebook iglu" />
      </a>
      <a target="_blank" href="https://www.instagram.com/igluseguros/">
        <img src="/assets/img/ins-circle.png" alt="instagram iglu" />
      </a>
      <a target="_blank" href="https://www.linkedin.com/company/igluseguros/">
        <img src="/assets/img/in-circle.png" alt="linkedin iglu" />
      </a>
    </div>
    <p class="sidenav__info color-black-1 m-0">
      lucas@igluseguros.com | +51 1 715-4400 <br />Calle Begonias 415, San
      Isidro - Lima, Perú
    </p>
  </div>
</div>
<div class="overlay-side" *ngIf="isActived"></div>
