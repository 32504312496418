import {
  Component,
  HostListener,
  Inject,
  Input,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  Router,
} from '@angular/router';
import { PunkuRoutes } from '../../../core/utils/punkuRoutes';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { FormStorageManagerService } from 'src/app/core/services/form-storage-manager.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  public headerClass = '';
  public scrolled = false;
  public routes = PunkuRoutes.ROUTES.Pages;
  public onlyLogo = false;
  public isBrokerUser: boolean = false;
  public visibleBtnQuote: boolean = false;
  public blueIconBurgerBroker: boolean = false;
  public visibleBtnQuoteBroker: boolean = true;
  public colorLogo = 'light-blue';
  public tokenBroker = null;

  @Input() heightHome: number = 0;
  @Output() activedSidenav: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private gtmService: GoogleTagManagerService,
    @Inject(DOCUMENT) private document: Document,
    private formStorageManagerService: FormStorageManagerService,
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.colorLogo =
          event.url.substring(1) === this.routes.SelectFlow.fullPath ||
          event.url.substring(1) === this.routes.SelectFlowBroker.fullPath ||
          event.url.substring(1) === this.routes.Continue.fullPath ||
          event.url.includes(this.routes.NewAccountBroker.fullPath) ||
          event.url.substring(1) === this.routes.HomeBroker.fullPath
            ? 'white'
            : 'light-blue';

        if (
          event.url.substring(1) === this.routes.Home.fullPath ||
          event.url.includes(
            this.routes.QuoteBroker.QuoteBrokerCompany.Coverage.fullPath
          ) ||
          event.url.includes(
            this.routes.QuoteBroker.QuoteBrokerCompany.EndQuote.fullPath
          ) ||
          event.url.includes(this.routes.QuotePerson.EndQuote.fullPath) ||
          event.url.includes(this.routes.Quote.Coverage.fullPath) ||
          event.url.includes(this.routes.Quote.EndQuote.fullPath) ||
          event.url.includes(this.routes.CodeGetInsurance.fullPath)
        ) {
          this.headerClass = '';
        } else if (
          event.url.includes('/quote/steps') ||
          event.url.includes('/quote-person/steps') ||
          event.url.includes('/quote-broker/company/steps') ||
          event.url.includes('/quote-broker/person/steps') ||
          event.url.includes('/quote-broker/natural-person/steps') ||
          event.url.includes('/quote-natural-person/steps') ||
          event.url.includes('/quote-pet/steps')
        ) {
          this.headerClass = 'nav--form';
        } else if (event.url.includes('/quote-pet/data')) {
          this.headerClass = 'nav--form display-logo-iglu';
        } else if (
          event.url.includes(this.routes.SelectFlow.fullPath) ||
          event.url.includes(this.routes.WhoIsHiring.fullPath) ||
          event.url.includes(this.routes.ChooseToppings.fullPath)
        ) {
          this.headerClass = 'nav--white';
        } else {
          this.headerClass = 'nav--secondary-style';
        }

        if (
          event.url ||
          event.url.includes(this.routes.Quote.EndQuote.fullPath) ||
          event.url.includes(this.routes.Niubiz.PropertyDirect.path) ||
          event.url.includes(this.routes.Niubiz.PropertyClient.path)
        ) {
          this.isBrokerUser = false;
          this.visibleBtnQuote = false;
          this.visibleBtnQuoteBroker = true;
          this.blueIconBurgerBroker = false;
        }

        if (
          event.url.includes('/quote') ||
          event.url.includes(this.routes.EndQuote.fullPath) ||
          event.url.includes(this.routes.CodeGetInsurance.fullPath) ||
          event.url.includes(this.routes.SelectFlow.fullPath) ||
          event.url.includes(this.routes.WhoIsHiring.fullPath) ||
          event.url.includes(this.routes.ChooseToppings.fullPath) ||
          event.url.includes(this.routes.NotApplicable.fullPath) ||
          event.url.includes(
            this.routes.QuoteBroker.QuoteBrokerCompany.fullPath
          ) ||
          event.url.substring(1) === this.routes.LoginBroker.fullPath ||
          event.url.substring(1) === this.routes.NewAccountBroker.fullPath
        ) {
          this.visibleBtnQuote = true;
        }

        if (
          event.url.includes(this.routes.SelectFlow.fullPath) ||
          event.url.includes(this.routes.WhoIsHiring.fullPath) ||
          event.url.includes(this.routes.ChooseToppings.fullPath) ||
          event.url.includes(this.routes.QuoteNaturalPerson.fullPath) ||
          event.url.includes(this.routes.QuotePet.fullPath)

        ) {
          this.visibleBtnQuoteBroker = false;
        }

        this.onlyLogo =
          event.url.substring(1) === this.routes.Continue.fullPath ||
          event.url.includes(this.routes.LoginBroker.fullPath) ||
          event.url.includes(this.routes.NewAccountBroker.fullPath) ||
          event.url.substring(1) === this.routes.HomeBroker.fullPath

        this.colorLogo =
          event.url.substring(1) === this.routes.SelectFlow.fullPath ||
          event.url.substring(1) === this.routes.WhoIsHiring.fullPath ||
          event.url.substring(1) === this.routes.ChooseToppings.fullPath ||
          event.url.substring(1) === this.routes.SelectFlowBroker.fullPath ||
          event.url.substring(1) === this.routes.CompanyUsesBroker.fullPath ||
          event.url.substring(1) === this.routes.LocalUsesBroker.fullPath ||
          event.url.substring(1) === this.routes.WhoIsHiringBroker.fullPath ||
          event.url.substring(1) === this.routes.ChooseToppingsBroker.fullPath ||
          event.url.substring(1) === this.routes.Continue.fullPath ||
          event.url.includes(this.routes.NewAccountBroker.fullPath) ||
          event.url.substring(1) === this.routes.HomeBroker.fullPath
            ? 'white'
            : 'light-blue';

        if (
          event.url.includes(this.routes.HomeBroker.fullPath) ||
          event.url.includes(this.routes.SelectFlowBroker.fullPath) ||
          event.url.includes(this.routes.WhoIsHiringBroker.fullPath) ||
          event.url.includes(this.routes.ChooseToppingsBroker.fullPath) ||
          event.url.includes(this.routes.CompanyUsesBroker.fullPath) ||
          event.url.includes(this.routes.LocalUsesBroker.fullPath) ||
          event.url.includes(this.routes.QuoteBroker.QuoteBrokerCompany.fullPath) ||
          event.url.includes(this.routes.QuoteBroker.QuoteBrokerPerson.fullPath) ||
          event.url.includes(this.routes.QuoteBroker.QuoteBrokerNaturalPerson.fullPath) ||
          event.url.includes(this.routes.QuoteHistoryBroker.fullPath) ||
          event.url.includes(this.routes.Niubiz.PropertyBroker.fullPath)
        ) {
          this.isBrokerUser = true;
        }

        if (
          event.url.includes(this.routes.QuoteBroker.QuoteBrokerCompany.fullPath) ||
          event.url.includes(this.routes.QuoteBroker.QuoteBrokerPerson.fullPath) ||
          event.url.includes(this.routes.QuoteBroker.QuoteBrokerNaturalPerson.fullPath) ||
          event.url.includes(this.routes.QuoteHistoryBroker.fullPath) ||
          event.url.includes(this.routes.Niubiz.PropertyBroker.fullPath)
        ) {
          this.blueIconBurgerBroker = true;
        }
      }
    });
  }

  ngOnInit(): void {}

  public showBtnQuote(): boolean {
    if (this.onlyLogo || this.visibleBtnQuote) return false;
    else return true;
  }

  public toggleSidenav() {
    this.activedSidenav.emit(true);
  }

  public scrollToElement(elementId: string): void {
    if (this.router.url !== '/')
      this.router.navigate([this.routes.Home.fullPath]);
    setTimeout(
      () => {
        this.viewportScroller.scrollToAnchor(elementId);
      },
      this.router.url !== '/' ? 700 : 100
    );
  }

  @HostListener('window:scroll', [])
  private onWindowScroll(): void {
    this.scrolled =
      document.body.scrollTop > 30 ||
      this.document.documentElement.scrollTop > 30;
  }

  public goQuote(): void {
    const gtmTag = {
      event: 'evento-interactivo',
      'evento-interactivo-categoria': 'Internas',
      'evento-interactivo-accion': 'Click',
      'evento-interactivo-etiqueta': 'Cabecera - Nueva cotización',
    };

    this.gtmService.pushTag(gtmTag);
    this.formStorageManagerService.removeTypeCode();
    this.router.navigate([this.routes.SelectFlow.fullPath]);
  }

  public goPropertyInsurance() {
    this.router.navigate([this.routes.Insurances.Property.fullPath]);
  }

  public goHome(): void {
    this.router.navigate([this.routes.Home.fullPath]);
  }

  public goAboutUs() {
    this.router.navigate([this.routes.AboutUs.fullPath]);
  }

  public goRC() {
    this.router.navigate([this.routes.Insurances.CivilLiability.fullPath]);
  }

  public goPet() {
    this.router.navigate([this.routes.Insurances.Pet.fullPath]);
  }

  public callMeBack() {
    this.router.navigate([this.routes.ContactSolicitude.fullPath]);
  }

  public lostPolicy() {
    this.router.navigate([this.routes.PolicySearch.fullPath]);
  }

  public registerSinister() {
    this.router.navigate([this.routes.ClaimRegister.fullPath]);
  }

  public cancelPolicy() {
    this.router.navigate([this.routes.PolicyCancellation.fullPath]);
  }

  public goFAQ() {
    this.router.navigate([this.routes.Faq.fullPath]);
  }

  public onClickMenuInsurances(e) {
    if (e) this.scrollToElement('insurances');
  }

  public getLogo() {
    if (this.scrolled) return '/assets/img/svg/logo-horizontal-light-blue.svg';
    return `/assets/img/svg/logo-horizontal-${this.colorLogo}.svg`;
  }

  public getBurger() {
    if (this.scrolled) return '/assets/img/svg/icon-burger-light-blue.svg';
    return `/assets/img/svg/icon-burger-${this.colorLogo}.svg`;
  }

  public goQuoteBroker() {
    this.tokenBroker = this.formStorageManagerService.getBrokerToken();
    if (this.tokenBroker) {
      this.router.navigate([this.routes.HomeBroker.fullPath]);
    } else {
      this.router.navigate([this.routes.LoginBroker.fullPath]);
    }
  }

  public getIconBurgerBroker() {
    if (this.scrolled || this.blueIconBurgerBroker)
      return '/assets/img/svg/icon-burger-light-blue.svg';
    return '/assets/img/svg/icon-burger-white.svg';
  }
}
