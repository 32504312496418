import {
  Component,
  Input,
  Output,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { InputTooltipModel } from '../../core/interfaces/input-tooltip-model';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent implements AfterViewInit {
  @Input() tooltipData: InputTooltipModel = {
    placement: 'top',
    close: true,
    trigger: 'click',
  };

  @Input() tooltipClass: string = 'my-custom-class';

  @Input() withIcon: boolean = true;

  @Input() hasCloseManual: boolean = false;

  @Input() initOpen: boolean = false;

  @Input() customIconClose: boolean = false;

  @Output() onCloseManual: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('tooltipRef', { static: false }) tooltipRef: NgbTooltip;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.initOpen) {
      this.tooltipRef.open();
    }
  }

  closeTooltip($event) {
    $event.preventDefault();
    this.tooltipRef.close();
    this.onCloseManual.emit(true);
  }
}
