<div class="loader" [ngClass]="{ hide: !show, 'loader--invisible': invisible }">
  <div class="loader__container">
    <img
      *ngIf="!invisible"
      class="loader__spinner"
      src="/assets/img/loading.gif"
      alt="Spinner"
    />
  </div>
</div>
