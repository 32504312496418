<button
  [attr.id]="id ? id : undefined"
  type="submit"
  class="btn btn-{{color}} {{buttonClass}} {{type && 'btn-' + type}}"
  [ngClass]="{
    'btn__outline': outline
  }"
  [disabled]="disabled"
>
  <app-spinner
    *ngIf="spinner"
    class="mr-2"
    [small]="true"
    [color]="color === 'primary' ? 'white': 'primary'"
  >
  </app-spinner>

  <i *ngIf="icon" class="pr-2">
    <img [src]="iconUrl" alt="icon">
  </i>

  <span
    *ngIf="color === 'secondary'"
    class="bold"
  >
    {{ text }}
  </span>

  <ng-template class="bold" [ngIf]="color !== 'secondary'">
    {{ text }}
  </ng-template>

  <i *ngIf="afterIcon">
    <img [src]="afterIconUrl" alt="afterIcon">
  </i>

  <ng-content></ng-content>
</button>
