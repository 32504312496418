<ng-template #tipContent>
  <div class="d-flex">
    <div *ngIf="tooltipData?.message" [innerHTML]="tooltipData?.message"></div>
    <button
      class="btn app-tooltip__close bold mt-1 ml-3"
      *ngIf="tooltipData?.close && !customIconClose"
    ></button>
    <button
      class="btn app-tooltip-close"
      *ngIf="tooltipData?.close && customIconClose"
      (click)="closeTooltip($event)"
    >
      <img
        src="/assets/img/svg/tooltip-close-icon.svg"
        class="app-tooltip-close__icon"
      />
    </button>
  </div>
  <img
    *ngIf="tooltipData?.imgSrc"
    [src]="tooltipData?.imgSrc"
    class="img-fluid mt-2"
    alt="Img"
  />
</ng-template>

<button
  #tooltipRef="ngbTooltip"
  type="button"
  class="app-tooltip"
  [triggers]="!hasCloseManual ? tooltipData?.trigger || 'click' : 'manual'"
  [autoClose]="!hasCloseManual"
  [placement]="tooltipData?.placement"
  [ngbTooltip]="tipContent"
  [tooltipClass]="tooltipClass"
>
  <i *ngIf="withIcon"
    ><img src="/assets/img/svg/icon-info.svg" alt="" width="14px"
  /></i>
</button>
