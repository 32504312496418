import { ContentChild, Directive, Input } from "@angular/core";
import { AccordionFormContent } from "./accordion-form-content.directive";
import { AccordionFormHeader } from "./accordion-form-header.directive";
import { AccordionFormTitle } from "./accordion-form-title.directive";

@Directive({
  selector: "accordion-form-item"
})
export class AccordionFormItem {
  @Input() title = "";
  @Input() disabled = false;
  @Input() actived = false;
  @Input() shadow = false;
  @Input() hidden = true;
  @ContentChild(AccordionFormContent) content: AccordionFormContent;
  @ContentChild(AccordionFormTitle) customTitle: AccordionFormTitle;
  @ContentChild(AccordionFormHeader) customHeader: AccordionFormHeader;
}
