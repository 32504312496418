import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PunkuRoutes } from 'src/app/core/utils/punkuRoutes';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SideNavComponent implements OnInit {
  @Input() isActived: boolean = false;
  @Output() isActivedChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  private routes = PunkuRoutes.ROUTES.Pages;

  public showHelpCenter: boolean = false;
  public showInsurances: boolean = false;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) this.hidingNavigation();
    });
  }

  ngOnInit(): void {}

  public scrollToElement(elementId: string): void {
    if (this.router.url !== '/')
      this.router.navigate([this.routes.Home.fullPath]);
    setTimeout(
      () => {
        this.hidingNavigation();
        this.viewportScroller.scrollToAnchor(elementId);
      },
      this.router.url !== '/' ? 500 : 100
    );
  }

  public hidingNavigation() {
    this.isActived = false;
    this.isActivedChange.emit(this.isActived);
  }

  public goQuote(): void {
    this.router.navigate([this.routes.SelectFlow.fullPath]);
  }

  public goClaims() {
    this.router.navigate([this.routes.Claims.fullPath]);
  }

  public goHome() {
    this.router.navigate([this.routes.Home.fullPath]);
  }

  public goAboutUs() {
    this.router.navigate([this.routes.AboutUs.fullPath]);
  }

  public goPropertyInsurance() {
    this.router.navigate([this.routes.Insurances.Property.fullPath]);
  }

  public goFaq() {
    this.router.navigate([this.routes.Faq.fullPath]);
  }

  public goRC() {
    this.router.navigate([this.routes.Insurances.CivilLiability.fullPath]);
  }

  public goPet() {
    this.router.navigate([this.routes.Insurances.Pet.fullPath]);
  }

  public callMeBack() {
    this.router.navigate([this.routes.ContactSolicitude.fullPath]);
  }

  public lostPolicy() {
    this.router.navigate([this.routes.PolicySearch.fullPath]);
  }

  public registerSinister() {
    this.router.navigate([this.routes.ClaimRegister.fullPath]);
  }

  public cancelPolicy() {
    this.router.navigate([this.routes.PolicyCancellation.fullPath]);
  }

  public goQuoteBroker() {
    this.router.navigate([this.routes.LoginBroker.fullPath]);
  }
}
