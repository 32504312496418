import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToastComponent implements OnInit, OnChanges {

  @Input() type: string = "error";
  @Input() body: string = "";
  @Input() duration: number = 3500;
  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!!changes.show?.currentValue) {
      setTimeout(() => {
        this.show = false;
        this.showChange.emit(this.show);
      }, this.duration || 3500);
    }
  }
}
