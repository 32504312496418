import { Injectable } from '@angular/core';
import { LocalStorage } from '../utils/localstorage';
import { Router } from '@angular/router';

import { PunkuRoutes } from '../../core/utils/punkuRoutes';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormStorageManagerService {
  public TYPE_CODE_DEFAULT = 'DEF';
  public TYPE_CODE_DEFAULT_PET = 'DEF_PET';
  public TYPE_CODE_COMPANY = 'PE';
  public TYPE_CODE_BROKER = 'RU';
  public TYPE_CODE_BROKER_PERSON = 'RUHOM';
  public TYPE_CODE_PERSON = 'DIHOM';
  public TYPE_CODE_NATURAL_PERSON = 'PNAT';
  public TYPE_CODE_BROKER_NATURAL_PERSON = 'RUNAT';
  public TYPE_CODE_PET = 'PET';

  private nameStorage: string;
  private promoDiscountNameStorage: string;
  private pricesNameStorage: string;
  private pricingSummaryNameStorage: string;

  constructor(private storage: LocalStorage, private router: Router) {}

  public getTypeCode(): string {
    return this.storage.get('typeCode');
  }

  public removeTypeCode() {
    this.storage.remove('typeCode');
  }

  public getNameStorage() {
    const typeCode = this.getTypeCode();
    if (typeCode === this.TYPE_CODE_DEFAULT)
      return (this.nameStorage = 'quoteFormDefault');
    if (typeCode === this.TYPE_CODE_DEFAULT_PET)
      return (this.nameStorage = 'quoteFormDefaultPet');
    if (typeCode === this.TYPE_CODE_COMPANY)
      return (this.nameStorage = 'quoteFormCompany');
    if (typeCode === this.TYPE_CODE_BROKER)
      return (this.nameStorage = 'quoteFormBroker');
    if (typeCode === this.TYPE_CODE_BROKER_PERSON)
      return (this.nameStorage = 'quoteFormBrokerPerson');
    if (typeCode === this.TYPE_CODE_PERSON)
      return (this.nameStorage = 'quoteFormPerson');
    if (typeCode === this.TYPE_CODE_NATURAL_PERSON)
      return (this.nameStorage = 'quoteFormNaturalPerson');
    if (typeCode === this.TYPE_CODE_BROKER_NATURAL_PERSON)
      return (this.nameStorage = 'quoteFormBrokerNaturalPerson');
    if (typeCode === this.TYPE_CODE_PET)
      return (this.nameStorage = 'quoteFormPet');
  }

  private getPromoDiscountNameStorage() {
    const typeCode = this.getTypeCode();
    if (typeCode === this.TYPE_CODE_COMPANY)
      return (this.promoDiscountNameStorage = 'promoDiscount');
    if (typeCode === this.TYPE_CODE_BROKER)
      return (this.promoDiscountNameStorage = 'promoDiscountBroker');
    if (typeCode === this.TYPE_CODE_PERSON)
      return (this.promoDiscountNameStorage = 'promoDiscountPerson');
    if (typeCode === this.TYPE_CODE_NATURAL_PERSON)
      return (this.promoDiscountNameStorage = 'promoDiscountNaturalPerson');
    if (typeCode === this.TYPE_CODE_BROKER_NATURAL_PERSON)
      return (this.promoDiscountNameStorage =
        'promoDiscountBrokerNaturalPerson');
    if (typeCode === this.TYPE_CODE_PET)
      return (this.promoDiscountNameStorage = 'promoDiscountPet');
  }

  private getPricesNameStorage() {
    const typeCode = this.getTypeCode();
    if (typeCode === this.TYPE_CODE_COMPANY)
      return (this.pricesNameStorage = 'prices');
    if (typeCode === this.TYPE_CODE_BROKER)
      return (this.pricesNameStorage = 'pricesBroker');
    if (typeCode === this.TYPE_CODE_BROKER_PERSON)
      return (this.pricesNameStorage = 'pricesBrokerPerson');
    if (typeCode === this.TYPE_CODE_PERSON)
      return (this.pricesNameStorage = 'pricesPerson');
    if (typeCode === this.TYPE_CODE_NATURAL_PERSON)
      return (this.pricesNameStorage = 'pricesNaturalPerson');
    if (typeCode === this.TYPE_CODE_BROKER_NATURAL_PERSON)
      return (this.pricesNameStorage = 'pricesBrokerNaturalPerson');
    if (typeCode === this.TYPE_CODE_PET)
      return (this.pricesNameStorage = 'pricesPet');
  }

  public getPricingSummaryNameStorage() {
    const typeCode = this.getTypeCode();
    if (typeCode === this.TYPE_CODE_COMPANY)
      return (this.pricingSummaryNameStorage = 'pricingSummary');
    if (typeCode === this.TYPE_CODE_BROKER)
      return (this.pricingSummaryNameStorage = 'pricingSummaryBroker');
    if (typeCode === this.TYPE_CODE_BROKER_PERSON)
      return (this.pricingSummaryNameStorage = 'pricingSummaryBrokerPerson');
    if (typeCode === this.TYPE_CODE_PERSON)
      return (this.pricingSummaryNameStorage = 'pricingSummaryPerson');
    if (typeCode === this.TYPE_CODE_NATURAL_PERSON)
      return (this.pricingSummaryNameStorage = 'pricingSummaryNaturalPerson');
    if (typeCode === this.TYPE_CODE_BROKER_NATURAL_PERSON)
      return (this.pricingSummaryNameStorage =
        'pricingSummaryBrokerNaturalPerson');
    if (typeCode === this.TYPE_CODE_PET)
      return (this.pricingSummaryNameStorage = 'pricingSummaryPet');
  }

  public getIsDefaultUser(): boolean {
    return this.getTypeCode() === this.TYPE_CODE_DEFAULT;
  }

  public getIsDefaultPetUser(): boolean {
    return this.getTypeCode() === this.TYPE_CODE_DEFAULT_PET;
  }

  public getIsCompanyUser(): boolean {
    return this.getTypeCode() === this.TYPE_CODE_COMPANY;
  }

  public getIsBrokerUser(): boolean {
    return this.getTypeCode() === this.TYPE_CODE_BROKER;
  }

  public getIsBrokerPersonUser(): boolean {
    return this.getTypeCode() === this.TYPE_CODE_BROKER_PERSON;
  }

  public getIsPersonUser(): boolean {
    return this.getTypeCode() === this.TYPE_CODE_PERSON;
  }

  public getIsNaturalPersonUser(): boolean {
    return this.getTypeCode() === this.TYPE_CODE_NATURAL_PERSON;
  }

  public getIsBrokerNaturalPersonUser(): boolean {
    return this.getTypeCode() === this.TYPE_CODE_BROKER_NATURAL_PERSON;
  }

  public getIsPetUser(): boolean {
    return this.getTypeCode() === this.TYPE_CODE_PET;
  }

  public hasCoverageRC(): boolean {
    if (this.getIsCompanyUser()) {
      const useOfPropertyFromStorage = this.storage.get(
        this.getNameStorage() + 'Use'
      );
      return useOfPropertyFromStorage.isRC;
    }

    if (this.getIsNaturalPersonUser() || this.getIsBrokerNaturalPersonUser()) {
      return false;
    }

    return true;
  }

  public getPropertyUse(): Object {
    return this.storage.get(
      this.getNameStorage() + 'Use'
    );
  }

  public getBrokerToken(): string {
    return this.storage.get('brokerToken');
  }

  public getBrokerUser(): any {
    return this.storage.get('broker');
  }
  public removeBrokerUser() {
    this.removeQuoteFormBroker();
    this.storage.remove('brokerToken');
    this.storage.remove('broker');
  }

  public getFormStorage(): any {
    this.getNameStorage();

    return this.nameStorage === 'quoteFormPet'
      ? this.storage.get(this.nameStorage) ||
          this.storage.get('quoteFormDefaultPet')
      : this.storage.get(this.nameStorage) ||
          this.storage.get('quoteFormDefault');
  }

  public saveFormStorage(quoteForm, pet = false) {
    this.nameStorage = this.getNameStorage();

    this.storage.set(
      pet && this.nameStorage === 'quoteFormDefault'
        ? 'quoteFormDefaultPet'
        : this.nameStorage,
      quoteForm
    );
  }

  public updateFromStorage() {
    this.getNameStorage();
    let quoteForm;

    if (this.nameStorage) {
      quoteForm = this.getFormStorage();

      if (quoteForm) {
        this.saveFormStorage(quoteForm);
      }

      if (this.nameStorage !== 'quoteFormDefault') {
        this.storage.remove('quoteFormDefault');
      }

      if (this.nameStorage !== 'quoteFormDefaultPet') {
        this.storage.remove('quoteFormDefaultPet');
      }
    }

    return quoteForm;
  }

  public getLocationStorage() {
    return this.storage.get('location');
  }

  public getUseStorage(): any {
    this.getNameStorage();
    return this.storage.get(`${this.nameStorage}Use`);
  }

  public getPromoDiscountStorage(): any {
    this.getPromoDiscountNameStorage();
    return this.storage.get(this.promoDiscountNameStorage);
  }
  public getPricesStorage(): any {
    this.getPricesNameStorage();
    return this.storage.get(this.pricesNameStorage);
  }
  public getPricingSummaryStorage(): any {
    this.getPricingSummaryNameStorage();
    return this.storage.get(this.pricingSummaryNameStorage);
  }

  public existsQuoteForm() {
    this.getNameStorage();
    return !!this.storage.get(this.nameStorage);
  }

  public toQuoteStep() {
    if (this.getIsBrokerUser()) {
      this.router.navigate([
        PunkuRoutes.ROUTES.Pages.QuoteBroker.QuoteBrokerCompany.Coverage
          .fullPath,
      ]);
    } else if (this.getIsBrokerPersonUser()) {
      this.router.navigate([
        PunkuRoutes.ROUTES.Pages.QuoteBroker.QuoteBrokerPerson.Coverage
          .fullPath,
      ]);
    } else if (this.getIsNaturalPersonUser()) {
      this.router.navigate([
        PunkuRoutes.ROUTES.Pages.QuoteNaturalPerson.Coverage.fullPath,
      ]);
    } else if (this.getIsBrokerNaturalPersonUser()) {
      this.router.navigate([
        PunkuRoutes.ROUTES.Pages.QuoteBroker.QuoteBrokerNaturalPerson.Coverage
          .fullPath,
      ]);
    } else if (this.getIsPersonUser()) {
      this.router.navigate([
        PunkuRoutes.ROUTES.Pages.QuotePerson.Coverage.fullPath,
      ]);
    } else {
      this.router.navigate([PunkuRoutes.ROUTES.Pages.Quote.Coverage.fullPath]);
    }
  }

  public toQuoteValidation() {
    const quoteForm = this.getFormStorage();

    const validationPet =
      this.getIsPetUser() &&
      this.existsQuoteForm() &&
      (quoteForm?.petBirthday ||
        quoteForm?.petName ||
        quoteForm?.petBreed ||
        quoteForm?.petWeight ||
        quoteForm?.petType);

    const validationNotPet =
      !this.getIsPetUser() &&
      this.existsQuoteForm() &&
      quoteForm?.currentStep !== null &&
      quoteForm?.currentStep !== undefined;

    if (validationPet || validationNotPet) {
      this.router.navigate([PunkuRoutes.ROUTES.Pages.Continue.fullPath]);
    } else {
      if (this.getIsBrokerUser()) {
        this.router.navigate([
          PunkuRoutes.ROUTES.Pages.CompanyUsesBroker.fullPath,
        ]);
      } else if (this.getIsBrokerPersonUser()) {
        this.router.navigate([
          PunkuRoutes.ROUTES.Pages.QuoteBroker.QuoteBrokerPerson.Coverage
            .fullPath,
        ]);
      } else if (this.getIsNaturalPersonUser()) {
        this.router.navigate([PunkuRoutes.ROUTES.Pages.LocalUses.fullPath]);
      } else if (this.getIsBrokerNaturalPersonUser()) {
        this.router.navigate([
          PunkuRoutes.ROUTES.Pages.LocalUsesBroker.fullPath,
        ]);
      } else if (this.getIsPersonUser()) {
        this.router.navigate([
          PunkuRoutes.ROUTES.Pages.QuotePerson.Coverage.fullPath,
        ]);
      } else if (this.getIsPetUser()) {
        this.router.navigate([
          PunkuRoutes.ROUTES.Pages.QuotePet.PetData.fullPath,
        ]);
      } else {
        this.router.navigate([PunkuRoutes.ROUTES.Pages.CompanyUses.fullPath]);
      }
    }
  }

  public toNewQuote() {
    this.clearQuoteFromStorage();

    if (this.getIsBrokerUser()) {
      this.router.navigate([
        PunkuRoutes.ROUTES.Pages.ChooseToppingsBroker.fullPath,
      ]);
    } else if (this.getIsBrokerPersonUser()) {
      this.router.navigate([
        PunkuRoutes.ROUTES.Pages.ChooseToppingsBroker.fullPath,
      ]);
    } else if (this.getIsNaturalPersonUser()) {
      this.router.navigate([
        PunkuRoutes.ROUTES.Pages.QuoteNaturalPerson.Coverage.fullPath,
      ]);
    } else if (this.getIsBrokerNaturalPersonUser()) {
      this.router.navigate([
        PunkuRoutes.ROUTES.Pages.QuoteBroker.QuoteBrokerNaturalPerson.Coverage
          .fullPath,
      ]);
    } else if (this.getIsPersonUser()) {
      this.router.navigate([
        PunkuRoutes.ROUTES.Pages.QuotePerson.Coverage.fullPath,
      ]);
    } else if (this.getIsPetUser()) {
      this.router.navigate([
        PunkuRoutes.ROUTES.Pages.QuotePet.PetData.fullPath,
      ]);
    } else {
      this.router.navigate([PunkuRoutes.ROUTES.Pages.ChooseToppings.fullPath]);
    }
  }

  public clearQuoteFromStorage() {
    this.removeFormStorage();
    this.removePromoDiscountStorage();
    this.removePricingSummaryStorage();
    this.removePricesStorage();
  }

  public toQuote() {
    if (this.getIsBrokerUser()) {
      this.router.navigate([
        PunkuRoutes.ROUTES.Pages.QuoteBroker.QuoteBrokerCompany.fullPath,
      ]);
    } else if (this.getIsBrokerPersonUser()) {
      this.router.navigate([
        PunkuRoutes.ROUTES.Pages.QuoteBroker.QuoteBrokerPerson.fullPath,
      ]);
    } else {
      this.router.navigate([PunkuRoutes.ROUTES.Pages.Quote.fullPath]);
    }
  }

  public removeFormStorage() {
    this.getNameStorage();
    this.storage.remove(this.nameStorage);
    this.storage.remove('location');

    if (this.getIsCompanyUser() || this.getIsBrokerUser()) {
      this.storage.remove('quoteFormCompanyUse');
    }
  }

  public removeQuoteFormBroker() {
    this.storage.remove('promoDiscountBroker');
    this.storage.remove('pricesBroker');
    this.storage.remove('pricingSummaryBroker');

    const typesCodes = [
      this.TYPE_CODE_BROKER,
      this.TYPE_CODE_BROKER_NATURAL_PERSON,
      this.TYPE_CODE_BROKER_PERSON,
    ];

    typesCodes.forEach((code) => {
      this.storage.set('typeCode', code);
      this.storage.remove(`${this.getNameStorage()}Use`);
      this.removeFormStorage();
    });

    this.storage.remove('typeCode');
  }

  public setPromoDiscountStorage(data) {
    this.getPromoDiscountNameStorage();
    this.storage.set(this.promoDiscountNameStorage, data);
  }
  public setPricesStorage(data) {
    this.getPricesNameStorage();
    this.storage.set(this.pricesNameStorage, data);
  }
  public setPricingSummaryStorage(data) {
    this.getPricingSummaryNameStorage();
    this.storage.set(this.pricingSummaryNameStorage, data);
  }

  public removePromoDiscountStorage() {
    this.getPromoDiscountNameStorage();
    this.storage.remove(this.promoDiscountNameStorage);
  }
  public removePricesStorage() {
    this.getPricesNameStorage();
    this.storage.remove(this.pricesNameStorage);
  }
  public removePricingSummaryStorage() {
    this.getPricingSummaryNameStorage();
    this.storage.remove(this.pricingSummaryNameStorage);
  }

  private verifyCurrentDayWithDates(date, notExistExpirationDate = false) {
    const currentDay = new Date();
    const currentDayUTC = Date.UTC(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      currentDay.getDate()
    );

    let newDate = new Date(date);
    if (notExistExpirationDate) {
      const days = 15;
      newDate.setDate(newDate.getDate() + days);
    }
    const newDateUTC = Date.UTC(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate()
    );

    return currentDayUTC > newDateUTC;
  }

  public verifyQuoteInStorage() {
    const typeCodes = [
      this.TYPE_CODE_DEFAULT,
      this.TYPE_CODE_DEFAULT_PET,
      this.TYPE_CODE_COMPANY,
      this.TYPE_CODE_BROKER,
      this.TYPE_CODE_BROKER_PERSON,
      this.TYPE_CODE_PERSON,
      this.TYPE_CODE_NATURAL_PERSON,
      this.TYPE_CODE_BROKER_NATURAL_PERSON,
    ];

    const flag = typeCodes.length - 1;

    return new Promise((resolve) => {
      typeCodes.forEach((code, index) => {
        this.storage.set('typeCode', code);
        const quote = this.getFormStorage();

        if (quote) {
          if (
            !quote.hasOwnProperty('expireAt') &&
            !quote.hasOwnProperty('createdAt')
          ) {
            this.removeFormStorage();
            return;
          }

          if (quote.hasOwnProperty('expireAt')) {
            const verifyDates = this.verifyCurrentDayWithDates(quote.expireAt);
            if (verifyDates) {
              this.removeFormStorage();
              return;
            }
          } else {
            const verifyDates = this.verifyCurrentDayWithDates(
              quote.createdAt,
              true
            );
            if (verifyDates) this.removeFormStorage();
          }
        }

        if (flag === index) {
          this.storage.remove('typeCode');
          resolve(true);
        }
      });
    });
  }

  public getControl(quoteForm = {}, name: string, valDef: any) {
    return quoteForm
      ? quoteForm.hasOwnProperty(name) &&
        quoteForm[name] !== null &&
        quoteForm[name] !== undefined
        ? quoteForm[name]
        : valDef
      : valDef;
  }

  public getControlTree(quoteForm = {}, prop: string, valDef: any) {
    const splitProps = prop.split('.');

    let lastValue: any = quoteForm;

    splitProps.forEach((prop) => {
      if (lastValue && lastValue.hasOwnProperty(prop) && !!lastValue?.[prop]) {
        lastValue = lastValue[prop];
      } else lastValue = valDef;
    });

    return lastValue;
  }

  public getArray(quoteForm = {}, name, valDef) {
    let formArray = [];
    let array = quoteForm ? quoteForm[name] || valDef : valDef;

    array.map((item) => {
      formArray.push(new FormControl(item));
    });

    return formArray;
  }
}
