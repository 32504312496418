import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LocalStorage } from '../utils/localstorage';

@Injectable({
  providedIn: 'any',
})
export class CommonDataService {
  private classSource = new BehaviorSubject(null);
  private currentStepSource = new BehaviorSubject(null);
  private quotationSource = new BehaviorSubject(null);
  private formSource = new BehaviorSubject(null);
  private loaderSource = new BehaviorSubject(false);
  private toastSource = new BehaviorSubject({ show: false });
  private popupCallMeBackSource = new BehaviorSubject(false);
  private pricingSource = new BehaviorSubject(null);
  private loadingPricingSource = new BehaviorSubject(false);
  private loadingAddressSource = new BehaviorSubject([false, false, false]);
  private documentValidationSource = new BehaviorSubject(null);
  private validValuesSource = new BehaviorSubject(false);
  private activeSideNavBroker$ = new BehaviorSubject(false);
  currentActiveSideNavBroker = this.activeSideNavBroker$.asObservable();
  currentClass = this.classSource.asObservable();
  currentStep = this.currentStepSource.asObservable();
  currentQuotation = this.quotationSource.asObservable();
  currentForm = this.formSource.asObservable();
  currentLoader = this.loaderSource.asObservable();
  currentToast = this.toastSource.asObservable();
  currentPopupCallMeBack = this.popupCallMeBackSource.asObservable();
  currentPricing = this.pricingSource.asObservable();
  currentLoadingPricing = this.loadingPricingSource.asObservable();
  currentLoadingAddress = this.loadingAddressSource.asObservable();
  currentDocumentValidation = this.documentValidationSource.asObservable();
  currentValidValues = this.validValuesSource.asObservable();
  nameStorage: string;
  currentUrl: string = '';
  previousUrl: string;

  constructor(private storage: LocalStorage, private router: Router) {
    if (this.currentUrl == '') {
      this.currentUrl = this.router.url;
      router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
        }
      });
    }
  }

  changeClass(currentClass: string) {
    this.classSource.next(currentClass);
  }

  changeCurrentStep(step) {
    this.currentStepSource.next(step);
  }

  changeQuotation(quotation) {
    this.quotationSource.next(quotation);
  }

  changeForm(form) {
    this.formSource.next(form);
  }

  changeLoader(showLoader) {
    this.loaderSource.next(showLoader);
  }

  changeToast(toast) {
    this.toastSource.next(toast);
  }

  changePopupCallMeBack(showPopup) {
    this.popupCallMeBackSource.next(showPopup);
  }

  changePricing(data) {
    this.pricingSource.next(data);
  }

  changeLoadingPricing(data) {
    this.loadingPricingSource.next(data);
  }

  changeLoadingAddress(data, index) {
    let newValue = this.loadingAddressSource.value;
    newValue[index] = data;

    this.loadingAddressSource.next(newValue);
  }

  changeDocumentValidation(data) {
    this.documentValidationSource.next(data);
  }

  changeValidValues(data) {
    this.validValuesSource.next(data);
  }

  removeIsBrokerUser() {
    this.storage.remove('isBrokenUser');
  }

  changeActiveSideNavBroker(value: boolean) {
    this.activeSideNavBroker$.next(value);
  }
}
