import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../../../core/interfaces/GenericResponse';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private apiUrl = `${environment.API_BASE_URL}/brokers`;

  constructor(private http: HttpClient) { }

  createTokenHeader(token): HttpHeaders {
    return new HttpHeaders({ 'g-captcha-token': token });
  }

  createBearerTokenHeader(recaptchaToken, token): HttpHeaders {
    return new HttpHeaders({
      'g-captcha-token': recaptchaToken,
      'Authorization': `Bearer ${token}` 
    });
  }

  loginBroker( 
    body,
    recaptchaToken: string   
  ): Observable<GenericResponse<any>> {
    const url = `${this.apiUrl}/loginBroker`;
    return this.http.post<GenericResponse<any>>(url, body,
      { headers: this.createTokenHeader(recaptchaToken) }  
    );
  }

  logoutBroker(token, recaptchaToken: string): Observable<GenericResponse<any>> {
    const url = `${this.apiUrl}/logoutBroker`;
    return this.http.post<GenericResponse<any>>(url, {},
      { headers: this.createBearerTokenHeader(recaptchaToken, token)}
    );
  }

  resetPass( 
    body,
    recaptchaToken: string   
  ): Observable<GenericResponse<any>> {
    const url = `${this.apiUrl}/resetPass`;
    return this.http.post<GenericResponse<any>>(url, body,
      { headers: this.createTokenHeader(recaptchaToken) }  
    );
  }
}
