import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavBrokerComponent } from './side-nav-broker.component';
import { PopupModule } from '../../popup/popup.module';
import { ButtonModule } from '../../button/button.module';



@NgModule({
  declarations: [SideNavBrokerComponent],
  imports: [
    CommonModule,
    PopupModule,
    ButtonModule
  ],
  exports: [SideNavBrokerComponent]
})
export class SideNavBrokerModule { }
