import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() type;
  @Input() color: string;
  @Input() text: string;
  @Input() buttonClass: string;
  @Input() icon: string;
  @Input() afterIcon: string;
  @Input() disabled: boolean = false;
  @Input() spinner: boolean = false;
  @Input() outline: boolean = false;
  @Input() id: string;

  constructor() {}

  ngOnInit(): void {}

  public get iconUrl(): string {
    const result = `assets/img/${this.icon}`;
    return result;
  }

  public get afterIconUrl(): string {
    const result = `assets/img/${this.afterIcon}`;
    return result;
  }
}
