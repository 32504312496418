export class PunkuRoutes {
  public static ROUTES = {
    Clean: '',
    Pages: {
      path: '',
      Home: {
        path: '',
        fullPath: '',
      },
      Product: {
        path: 'products/:id',
        fullPath: 'products/:id',
      },
      Niubiz: {
        path: 'niubiz',
        fullPath: 'niubiz',
        PropertyDirect: {
          path: 'niubiz/transaction',
          fullPath: 'niubiz/transaction/:token',
        },
        PropertyBroker: {
          path: 'niubiz-broker/transaction',
          fullPath: 'niubiz-broker/transaction',
        },
        PropertyClient: {
          path: 'niubiz-client/transaction',
          fullPath: 'niubiz-client/transaction/:token'
        }
      },
      Quote: {
        path: 'quote',
        fullPath: 'quote',
        StepContainer: {
          path: '',
          fullPath: 'quote/',
        },
        // Industry: {
        //   path: 'industry',
        //   fullPath: 'quote/industry',
        // },
        Coverage: {
          path: 'coverage',
          fullPath: 'quote/coverage',
        },
        StepOne: {
          path: 'steps/one',
          fullPath: 'quote/steps/one',
        },
        StepTwo: {
          path: 'steps/two',
          fullPath: 'quote/steps/two',
        },
        StepThree: {
          path: 'steps/three',
          fullPath: 'quote/steps/three',
        },
        StepFour: {
          path: 'steps/four',
          fullPath: 'quote/steps/four',
        },
        EndQuote: {
          path: 'endquote',
          fullPath: 'quote/endquote',
        }
      },
      QuoteBroker: {
        path: 'quote-broker',
        fullPath: 'quote-broker',
        QuoteBrokerCompany: {
          path: 'quote-broker/company',
          fullPath: 'quote-broker/company',
          StepContainer: {
            path: '',
            fullPath: 'quote-broker/company/',
          },
          // Industry: {
          //   path: 'industry',
          //   fullPath: 'quote-broker/company/industry',
          // },
          Coverage: {
            path: 'coverage',
            fullPath: 'quote-broker/company/coverage',
          },
          StepOne: {
            path: 'steps/one',
            fullPath: 'quote-broker/company/steps/one',
          },
          StepTwo: {
            path: 'steps/two',
            fullPath: 'quote-broker/company/steps/two',
          },
          StepThree: {
            path: 'steps/three',
            fullPath: 'quote-broker/company/steps/three',
          },
          StepFour: {
            path: 'steps/four',
            fullPath: 'quote-broker/company/steps/four',
          },
          StepFive: {
            path: 'steps/five',
            fullPath: 'quote-broker/company/steps/five',
          },
          EndQuote: {
            path: 'endquote',
            fullPath: 'quote-broker/company/endquote',
          }
        },
        QuoteBrokerPerson: {
          path: 'quote-broker/person',
          fullPath: 'quote-broker/person',
          StepContainer: {
            path: '',
            fullPath: 'quote-broker/person/',
          },
          Coverage: {
            path: 'coverage',
            fullPath: 'quote-broker/person/coverage',
          },
          StepOne: {
            path: 'steps/one',
            fullPath: 'quote-broker/person/steps/one',
          },
          StepTwo: {
            path: 'steps/two',
            fullPath: 'quote-broker/person/steps/two',
          },
          StepThree: {
            path: 'steps/three',
            fullPath: 'quote-broker/person/steps/three',
          },
          StepFour: {
            path: 'steps/four',
            fullPath: 'quote-broker/person/steps/four',
          },
          StepFive: {
            path: 'steps/five',
            fullPath: 'quote-broker/person/steps/five',
          },
          EndQuote: {
            path: 'endquote',
            fullPath: 'quote-broker/person/endquote',
          }
        },
        QuoteBrokerNaturalPerson: {
          path: 'quote-broker/natural-person',
          fullPath: 'quote-broker/natural-person',
          StepContainer: {
            path: '',
            fullPath: 'quote-broker/natural-person/',
          },
          Coverage: {
            path: 'coverage',
            fullPath: 'quote-broker/natural-person/coverage',
          },
          StepOne: {
            path: 'steps/one',
            fullPath: 'quote-broker/natural-person/steps/one',
          },
          StepTwo: {
            path: 'steps/two',
            fullPath: 'quote-broker/natural-person/steps/two',
          },
          StepThree: {
            path: 'steps/three',
            fullPath: 'quote-broker/natural-person/steps/three',
          },
          StepFour: {
            path: 'steps/four',
            fullPath: 'quote-broker/natural-person/steps/four',
          },
          StepFive: {
            path: 'steps/five',
            fullPath: 'quote-broker/natural-person/steps/five',
          },
          EndQuote: {
            path: 'endquote',
            fullPath: 'quote-broker/natural-person/endquote',
          }
        },
      },
      QuotePerson: {
        path: 'quote-person',
        fullPath: 'quote-person',
        StepContainer: {
          path: '',
          fullPath: 'quote-person/',
        },
        Coverage: {
          path: 'coverage',
          fullPath: 'quote-person/coverage',
        },
        StepOne: {
          path: 'steps/one',
          fullPath: 'quote-person/steps/one',
        },
        StepTwo: {
          path: 'steps/two',
          fullPath: 'quote-person/steps/two',
        },
        StepThree: {
          path: 'steps/three',
          fullPath: 'quote-person/steps/three',
        },
        StepFour: {
          path: 'steps/four',
          fullPath: 'quote-person/steps/four',
        },
        StepFive: {
          path: 'steps/five',
          fullPath: 'quote-person/steps/five',
        },
        EndQuote: {
          path: 'endquote',
          fullPath: 'quote-person/endquote',
        }
      },
      QuoteNaturalPerson: {
        path: 'quote-natural-person',
        fullPath: 'quote-natural-person',
        StepContainer: {
          path: '',
          fullPath: 'quote-natural-person/',
        },
        Coverage: {
          path: 'coverage',
          fullPath: 'quote-natural-person/coverage',
        },
        StepOne: {
          path: 'steps/one',
          fullPath: 'quote-natural-person/steps/one',
        },
        StepTwo: {
          path: 'steps/two',
          fullPath: 'quote-natural-person/steps/two',
        },
        StepThree: {
          path: 'steps/three',
          fullPath: 'quote-natural-person/steps/three',
        },
        StepFour: {
          path: 'steps/four',
          fullPath: 'quote-natural-person/steps/four',
        },
        StepFive: {
          path: 'steps/five',
          fullPath: 'quote-natural-person/steps/five',
        },
        EndQuote: {
          path: 'endquote',
          fullPath: 'quote-natural-person/endquote',
        }
      },
      QuotePet: {
        path: 'quote-pet',
        fullPath: 'quote-pet',
        StepContainer: {
          path: '',
          fullPath: 'quote-pet/',
        },
        PetData: {
          path: 'data',
          fullPath: 'quote-pet/data',
        },
        StepOne: {
          path: 'steps/one',
          fullPath: 'quote-pet/steps/one',
        },
        StepTwo: {
          path: 'steps/two',
          fullPath: 'quote-pet/steps/two',
        },
        StepThree: {
          path: 'steps/three',
          fullPath: 'quote-pet/steps/three',
        },
        EndQuote: {
          path: 'endquote',
          fullPath: 'quote-pet/endquote',
        },
        EndQuoteNiubiz: {
          path: 'quote-pet/endquote-niubiz',
          fullPath: 'quote-pet/endquote-niubiz/:token',
        },
      },
      AfterSaleServices: {
        path: 'after-sale-services',
        fullPath: 'after-sale-services',
      },
      ClaimRegister: {
        path: 'claim',
        fullPath: 'claim',
        Pet: {
          path: 'pet',
          fullPath: 'claim/pet',
          PetStepOne: {
            path: 'step-one',
            fullPath: 'claim/pet/step-one',
          },
          PetStepTwo: {
            path: 'step-two',
            fullPath: 'claim/pet/step-two',
          },
          PetStepThree: {
            path: 'step-three',
            fullPath: 'claim/pet/step-three',
          },
        },
        Home: {
          path: 'home',
          fullPath: 'claim/home',
          HomeStepOne: {
            path: 'step-one',
            fullPath: 'claim/home/step-one',
          },
          HomeStepTwo: {
            path: 'step-two',
            fullPath: 'claim/home/step-two',
          },
          HomeStepThree: {
            path: 'step-three',
            fullPath: 'claim/home/step-three',
          },
        },
        Company: {
          path: 'company',
          fullPath: 'claim/company',
          CompanyStepOne: {
            path: 'step-one',
            fullPath: 'claim/company/step-one',
          },
          CompanyStepTwo: {
            path: 'step-two',
            fullPath: 'claim/company/step-two',
          },
          CompanyStepThree: {
            path: 'step-three',
            fullPath: 'claim/company/step-three',
          },
        },
      },
      Claims: {
        path: 'claims',
        fullPath: 'claims',
      },
      EndQuote: {
        path: 'endquote',
        fullPath: 'endquote',
      },
      PolicyCancellation: {
        path: 'policy-cancellation',
        fullPath: 'policy-cancellation',
        Pet: {
          path: 'pet',
          fullPath: 'policy-cancellation/pet',
        },
        Home: {
          path: 'home',
          fullPath: 'policy-cancellation/home',
        },
        Local: {
          path: 'local',
          fullPath: 'policy-cancellation/local',
        },
        Company: {
          path: 'company',
          fullPath: 'policy-cancellation/company',
        },
      },
      PolicySearch: {
        path: 'policy-search',
        fullPath: 'policy-search',
        Success: {
          path: 'success',
          fullPath: 'policy-search/success',
        },
        Pet: {
          path: 'pet',
          fullPath: 'policy-search/pet',
        },
        Home: {
          path: 'home',
          fullPath: 'policy-search/home',
        },
        Local: {
          path: 'local',
          fullPath: 'policy-search/local',
        },
        Company: {
          path: 'company',
          fullPath: 'policy-search/company',
        },
      },
      Sorry: {
        path: 'sorry',
        fullPath: 'sorry',
      },
      NotApplicable: {
        path: 'not-applicable',
        fullPath: 'not-applicable',
      },
      NotApplicablePet: {
        path: 'not-applicable-pet',
        fullPath: 'not-applicable-pet',
      },
      NotApplicablePerson: {
        path: 'not-applicable-person',
        fullPath: 'not-applicable-person',
      },
      NotApplicableBrokerPerson: {
        path: 'not-applicable-broker-person',
        fullPath: 'not-applicable-broker-person',
      },
      NotApplicableBrokerCompany: {
        path: 'not-applicable-broker-company',
        fullPath: 'not-applicable-broker-company',
      },
      NotApplicableCompany: {
        path: 'not-applicable-company',
        fullPath: 'not-applicable-company',
      },
      SorryEquifax: {
        path: 'sorry-equifax',
        fullPath: 'sorry-equifax',
      },
      ContactSolicitude: {
        path: 'contact-solicitude',
        fullPath: 'contact-solicitude',
      },
      Success: {
        path: 'success',
        fullPath: 'success',
      },
      Faq: {
        path: 'faq',
        fullPath: 'faq',
      },
      AboutUs: {
        path: 'about-us',
        fullPath: 'about-us',
      },
      Coverages: {
        path: 'coverages',
        fullPath: 'coverages',
      },
      Continue: {
        path: 'continue',
        fullPath: 'continue',
      },
      LoginBroker: {
        path: 'login-broker',
        fullPath: 'login-broker',
      },
      Redirect: {
        path: 'redirect',
        fullPath: 'redirect',
      },
      CodeGetInsurance: {
        path: 'code-get-insurance',
        fullPath: 'code-get-insurance',
      },
      SelectFlow: {
        path: 'select-flow',
        fullPath: 'select-flow',
      },
      Insurances: {
        path: 'insurances',
        fullPath: 'insurances',
        Property: {
          path: 'property',
          fullPath: 'insurances/property',
        },
        CivilLiability: {
          path: 'civil-liability',
          fullPath: 'insurances/civil-liability',
        },
        Pet: {
          path: 'pet',
          fullPath: 'insurances/pet',
        },
      },
      SelectFlowBroker: {
        path: 'select-flow-broker',
        fullPath: 'select-flow-broker',
      },
      HomeBroker: {
        path: 'home-broker',
        fullPath: 'home-broker',
      },
      QuoteHistoryBroker: {
        path: 'history',
        fullPath: 'history',
      },
      NewAccountBroker: {
        path: 'new-account-broker',
        fullPath: 'new-account-broker',
      },
      WhoIsHiring: {
        path: 'who-is-hiring',
        fullPath: 'who-is-hiring',
      },
      WhoIsHiringBroker: {
        path: 'who-is-hiring-broker',
        fullPath: 'who-is-hiring-broker',
      },
      ChooseToppings: {
        path: 'choose-toppings',
        fullPath: 'choose-toppings',
      },
      ChooseToppingsBroker: {
        path: 'choose-toppings-broker',
        fullPath: 'choose-toppings-broker',
      },
      FormCivilLiability: {
        path: 'form-civil-liability',
        fullPath: 'form-civil-liability',
      },
      CompanyUses: {
        path: 'company-uses',
        fullPath: 'company-uses',
      },
      CompanyUsesBroker: {
        path: 'company-uses-broker',
        fullPath: 'company-uses-broker',
      },
      LocalUses: {
        path: 'local-uses',
        fullPath: 'local-uses',
      },
      LocalUsesBroker: {
        path: 'local-uses-broker',
        fullPath: 'local-uses-broker',
      },
      SendDocuments: {
        path: 'send-documents',
        fullPath: 'send-documents',
      },
      StandaloneRCBroker: {
        path: 'standalone-rc-broker',
        fullPath: 'standalone-rc-broker',
      },
    },
  };
}
